@import '@ping/assets/scss/theme/theme';

.page {
  /* Start the scroll bar from under the navbar */
  /* Related changes: "src/assets/scss/base/_common.scss" */
  overflow: auto;
  position: absolute;
  top: $header-height;
  height: calc(100% - $header-height);
  width: 100%;
}
